<script setup>
    import { computed } from "vue";
    import { Link } from "@inertiajs/vue3";

    const props = defineProps({
        active: Boolean,
        href: String,
        as: String,
    });

    const classes = computed(() => {
        return props.active
            ? "block w-full ps-3 pe-4 py-2 border-l-4 border-orange-400 text-start text-base font-medium text-orange-700 bg-orange-50 focus:outline-hidden focus:text-indigo-800 focus:bg-indigo-100 focus:border-indigo-700 transition duration-150 ease-in-out"
            : "block w-full ps-3 pe-4 py-2 border-l-4 border-transparent text-start text-base font-medium text-white hover:text-gray-800 hover:bg-gray-50 hover:border-gray-300 focus:outline-hidden focus:text-gray-800 focus:bg-gray-50 focus:border-gray-300 transition duration-150 ease-in-out";
    });
</script>

<template>
    <div>
        <button v-if="as === 'button'" :class="classes" class="w-full text-start">
            <slot />
        </button>

        <Link v-else :class="classes" :href="href">
            <slot />
        </Link>
    </div>
</template>
