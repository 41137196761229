<script setup>
    import { computed } from "vue";
    import { Link } from "@inertiajs/vue3";

    const props = defineProps({
        href: String,
        active: Boolean,
    });

    const classes = computed(() => {
        return props.active
            ? "inline-flex items-center px-1 pt-1 border-b-2 border-orange-400 text-md font-medium leading-5 text-gray-400 focus:outline-hidden focus:border-orange-700 transition duration-150 ease-in-out"
            : "inline-flex items-center px-1 pt-1 border-b-2 border-transparent text-md font-medium leading-5 text-white hover:text-gray-300 hover:border-gray-300 focus:outline-hidden focus:text-gray-400 focus:border-gray-300 transition duration-150 ease-in-out";
    });
</script>

<template>
    <Link :href="href" :class="classes">
        <slot />
    </Link>
</template>
